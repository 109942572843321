
html,
body {
  font-family: 'Roboto'
}

#App {
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  background-color: #DDD0C8;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

